import axios from "axios";
import config from "./api.config";

export function loginApi({ email, password }) {
  return axios.post(`${config.API_URL}/auth/login`, {
    email,
    password,
  });
}

export function googleLoginApi({ credential }) {
  return axios.post(`${config.API_URL}/auth/google-login`, {
    credential,
  });
}

export function googleRegisterApi({ credential, agreeToNewsletter, tags }) {
  let utmData;
  try {
    utmData = JSON.parse(localStorage.getItem("uptimeline_utmData"));
  } catch (e) {}
  return axios.post(`${config.API_URL}/auth/google-register`, {
    credential,
    agreeToNewsletter,
    tags: {
      ...tags,
      ...(utmData || {}),
    },
  });
}

export function forgotPasswordApi({ email }) {
  return axios.post(`${config.API_URL}/auth/forgotPassword`, {
    email,
  });
}

export function resetPasswordApi({ email, code, newPassword }) {
  return axios.post(`${config.API_URL}/auth/resetPassword`, {
    email,
    code,
    newPassword,
  });
}

export function changePasswordApi({ oldPassword, newPassword }) {
  return axios.post(`${config.API_URL}/auth/changePassword`, {
    password: oldPassword,
    newPassword,
  });
}

export function registerApi({
  name,
  surname,
  email,
  password,
  role = "user",
  agreeToNewsletter,
}) {
  let utmData;
  try {
    utmData = JSON.parse(localStorage.getItem("uptimeline_utmData"));
  } catch (e) {}
  return axios.post(`${config.API_URL}/auth/register`, {
    email,
    password,
    name,
    surname,
    role,
    agreeToNewsletter,
    utmData: utmData || undefined,
  });
}

export function updateProfileApi({ phoneNumber, name, surname, tags }) {
  return axios.post(`${config.API_URL}/auth/updateProfile`, {
    phoneNumber,
    name,
    surname,
    tags,
  });
}

export function getProfileApi() {
  return axios.get(`${config.API_URL}/auth/profile`);
}
